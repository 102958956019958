import React, { Suspense } from 'react'
import {Home, TestComponent} from '../components'
import { Switch, Route } from 'react-router-dom'

const LegacyRoutes = React.lazy(() => import('./legacy-routes'))

export const NewRoutes =() => {
    return (
        <Switch>
            <Route path={["/app", "/login"]}>
                <Suspense fallback={ <div>loading</div>}>
                    <LegacyRoutes />
                </Suspense>
            </Route>
            <Route path='/test'>
                <TestComponent />
            </Route>
            <Route path="/">
                <Home />
            </Route>
        </Switch>
    )
}