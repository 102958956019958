import React from 'react';
import './App.css';
import { Router } from 'react-router-dom';
import { NewRoutes } from './routes/new-routes';
import { NavBar } from './components';
import { createBrowserHistory } from 'history';
import {
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';
window.$ = window.jQuery = require('jquery');

const drawerWidth = 240;
const history = createBrowserHistory();
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);
function App() {
  const classes = useStyles();
  return (
    <Router history={history}>
      <NavBar />
      <main className={classes.appBar}>
        <NewRoutes />
      </main>
    </Router>
  );
}

export default App;
