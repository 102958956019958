import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import Apple from '@material-ui/icons/Apple';
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

export const NavBar = () => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}></div>
      <Divider />
      <Typography variant="h6">React Components</Typography>
      <List>
        {[
          { text: 'Home', to: '/', Icon: HomeIcon },
          { text: 'Test Component', to: '/test', Icon: Apple },
        ].map((item, index) => (
          <ListItem
            button
            key={item.text}
            onClick={() => history.push(item.to)}
          >
            <ListItemIcon>
              <item.Icon />
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <Typography variant="h6">Angular Components</Typography>
      <List>
        {[
          { text: 'Legacy Home', to: '/app/home', Icon: HomeWorkIcon },
          {
            text: 'Contacts',
            to: '/app/contacts',
            Icon: ImportContactsIcon,
          },
          {
            text: 'Tasks',
            to: '/app/tasks/0?page=1',
            Icon: AssignmentTurnedInIcon,
          },
          {
            text: 'Legacy Education Component',
            to: '/app/education-and-qualifications',
            Icon: Apple,
          },
        ].map((item, index) => (
          <ListItem
            button
            key={item.text}
            onClick={() => history.push(item.to)}
          >
            <ListItemIcon>
              <item.Icon />
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );
  return (
    <nav>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};
