import React from 'react';
import {Container, Paper} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      width: '100%',
      maxWidth: 500,
    },
  });

export const TestComponent = () => {
  const classes = useStyles();
    return (
        <Container>
            <Paper elevation={3} >
                <div className={classes.root}>
                    <Typography>
                        Test Component
                    </Typography>
                </div>
            </Paper>
        </Container>
    )
}